import React, { useState, useMemo } from 'react';
import styles from './FlexiTile.module.scss';
import classNames from 'classnames';
import { isUndefined } from './ImageBlockUtilities';
import Title from './textComponents/Title';
import SubTitle from './textComponents/SubTitle';
import PropTypes from 'prop-types';
import { LinkButton, LazyImage } from 'sana/elements';
const imageScalingType = [
  'imageBestView',
  'imageBaseOnTheWidth',
  'imageBaseOnHeight',
  'imageDoNotScale',
];
function ImageBlock({ elementId, tileData, textPositionClassNamesString, tileBackgroundStyles, buttonDisplayStyle, adminClasses }) {

  const [hover, setHover] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { button, background, buttonLink, text } = { ...tileData };
  const placeholderCss = loaded ? styles.imageSkeleton : styles.imagePlaceholder;

  let linkStyle = {
    color: (!isUndefined(button) && !isUndefined(button.buttonTextColor)) &&
      button.buttonTextColor,
    background: (!isUndefined(button) && !isUndefined(button.buttonBackgroundColor)) &&
      button.buttonBackgroundColor,
    boxShadow: (!isUndefined(button) && !isUndefined(button.buttonBorderColor)) &&
      `inset 0 -1px 0 ${button.buttonBorderColor}`,
    display: buttonDisplayStyle,
  };

  const wcagButtonAttributes = {};

  if (button.buttonAltText)
    wcagButtonAttributes['aria-label'] = button.buttonAltText;

  if (hover)
    linkStyle = {
      ...linkStyle,
      backgroundColor: (!isUndefined(button) && !isUndefined(button.buttonBackgroundHoverColor)) &&
        button.buttonBackgroundHoverColor,
      boxShadow: (!isUndefined(button) && !isUndefined(button.buttonBorderHoverColor)) &&
        `inset 0 -1px 0 ${button.buttonBorderHoverColor}`,
    };
  else
    linkStyle = {
      ...linkStyle,
      backgroundColor: (!isUndefined(button) && !isUndefined(button.buttonBackgroundColor)) &&
        button.buttonBackgroundColor,
      boxShadow: (!isUndefined(button) && !isUndefined(button.buttonBorderColor)) &&
        `inset 0 -1px 0 ${button.buttonBorderColor}`,
    };
    const backgroundImageTag = useMemo(() => {
      const { backgroundImagePath, imageAltText, imageScaling } = {
        ...tileData.background,
      };
      return (
        <LazyImage
          afterLoad={() => setLoaded(true)}
          src={backgroundImagePath}
          alt={imageAltText}
          className={classNames(
            styles.tileImage,
            styles[imageScalingType[imageScaling]],
          )}
          draggable="false"
        />
      );
    }, [
      background.backgroundImagePath,
      background.imageAltText,
      background.imageScaling,
    ]);
  return (
    <div id={elementId} className={classNames(styles.flexTileBlock, 'flexi-tile-content-block')} tabIndex="0" role="presentation">
      <div className={styles.tileBlockImg} style={tileBackgroundStyles}>
        <div className={styles.imageContainer}>
          {background.useBackgroundImage &&
            <div className={styles.placeholderWrapper}>
              <div className={classNames(placeholderCss, styles.inner, adminClasses)} />
              {backgroundImageTag}
            </div>
          }
          
          {(text.title.length !== 0 || text.subtitle.length !== 0 || button.buttonText.length !== 0 )&&
            <div className={classNames(styles.textOverlay, textPositionClassNamesString)}>
            <div className={styles.textBlock}>
              {text.title.length !== 0 && <Title tileConfigData={text} />}
              {text.subtitle.length !== 0 && <SubTitle tileConfigData={text} />}
              {button.buttonText !== 0 &&
               <LinkButton {
                 ...(!isUndefined(buttonLink) && !isUndefined(buttonLink.navigationLink)) && buttonLink.navigationLink}
                 style={linkStyle}
                 onMouseEnter={() => { setHover(!hover); }}
                 onMouseLeave={() => { setHover(!hover); }}
                 className="flexi-tile-button-click"
                 {...wcagButtonAttributes}
               >
                {button.buttonText}
              </LinkButton>}
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

ImageBlock.propTypes = {
  tileData: PropTypes.shape({
    buttonLink: PropTypes.shape({
      buttonTextPosition: PropTypes.number,
    }),
    background: PropTypes.shape({
      useBackgroundImage: PropTypes.bool,
      backgroundImagePath: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
    button: PropTypes.shape({
      buttonText: PropTypes.string,
      backgroundColor: PropTypes.string,
      buttonTextColor: PropTypes.string,
      buttonBackgroundColor: PropTypes.string,
      buttonBorderColor: PropTypes.string,
      buttonBackgroundHoverColor: PropTypes.string,
      buttonBorderHoverColor: PropTypes.string,
    }),
    url: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    Price: PropTypes.number,
  }),
};
export default React.memo(ImageBlock);
